import Vue from 'vue'
import App from './App.vue'
import PrismicVue from '@prismicio/vue'

const accessToken = 'MC5YNGVGVXhBQUFDTUFaU0hf.XRTvv71Z77-9W346MnPvv73vv73vv71gXn_vv70Z77-9T--_vQZJLk7vv73vv70MYO-_ve-_ve-_vQ' // Leave empty if your repo is public
const endpoint = 'https://dismoicombien.cdn.prismic.io/api/v2' // Use your repo name

Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
