<template>

  <div>
    <HeaderLayout></HeaderLayout>

    <main>
      <section class="price">
        <div class="container">
          <div class="price_block">
            <p>Avec</p>
            <p>
              <input
                  class="price_block_field"
                  autoComplete="off"
                  type="number"
                  v-model="price"
                  placeholder="10000"
                  v-on:keyup.enter="findItem"
                  v-on:keyup="resizeInput"
                  ref="price"
                  :style="`max-width: ` + inputWidth + `px`"
              />
              €
            </p>
            <button
                @click="findItem"
                :disabled="isDisabled"
            >Je peux avoir...</button>
          </div>
          <div class="price_fautPasDeconner" v-if="warningPrice">
            <p>
              Même la personne la plus riche sur terre n'a pas ça et j'ai jamais vu un loto pour gagner cette somme... <br>
              Descends un peu ton imagination stp
            </p>
          </div>
        </div>
      </section>

      <section class="itemsContainer">
        <div
            v-if="isLoading"
            class="itemsContainer_loader"
        >
          <div class="itemsContainer_loader_wrapper">
            <Loading></Loading>
          </div>
        </div>
        <div
            v-if="! isLoading && itemAPI"
            class="itemsContainer_list"
        >
          <ul>
            <Item v-for="n in itemData.countDisplay" v-bind:key="n" :icon="itemAPI.data.icon[0].text"></Item>
          </ul>

          <p
              v-if="itemData.itemsMissing"
              class="itemsContainer_list_more"
          >
            Et encore, il manque à afficher {{ formatNumber(itemData.itemsMissing) }} éléments (mais désolé problème de perfs)
          </p>
        </div>
      </section>

      <div
          v-if="itemAPI && ! isLoading"
          class="bottomInformations"
      >
        <div class="bottomInformations_left">
          <div class="bottomInformations_left_stuff">
            <p>
              {{ formatNumber(itemData.count) }} {{ itemData.count > 1 ? itemAPI.data.title_plural[0].text : itemAPI.data.title_singular[0].text }}
            </p>
            <a
                v-if="itemAPI.data.learn_more.url"
                :href="itemAPI.data.learn_more.url"
                class="bottomInformations_left_stuff_learnMore"
                target="_blank"
            >
              En savoir plus
            </a>

          </div>
          <ul class="bottomInformations_left_share">
            <li>
              <a :href="`https://www.facebook.com/sharer/sharer.php?u=https://www.dismoicombien.fr/&quote=Avec ` + formatNumber(price) + `€, je pourrais avoir ` + formatNumber(itemData.count) + ` ` + (itemData.count > 1 ? itemAPI.data.title_plural[0].text : itemAPI.data.title_singular[0].text) + ` !`" class="facebook" title="Partager sur Facebook" target="_blank" rel="noopener noreferrer">
                <svg-facebook></svg-facebook>
              </a>
            </li>
            <li>
              <a :href="`https://twitter.com/intent/tweet?text=Avec ` + formatNumber(price) + `€, je pourrais avoir ` + formatNumber(itemData.count) + ` ` + (itemData.count > 1 ? itemAPI.data.title_plural[0].text : itemAPI.data.title_singular[0].text) + ` ! @DisMoiCombien`" class="twitter" title="Partager sur Twitter" target="_blank" rel="noopener noreferrer">
                <svg-twitter></svg-twitter>
              </a>
            </li>
          </ul>
        </div>
        <div class="bottomInformations_right">
          <button
            @click="findItem"
            :disabled="isDisabled"
          >Autre chose !</button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import HeaderLayout from './layout/HeaderLayout.vue'
import Item from './components/item.vue'
import Loading from './components/SVG/Loading.vue'
import SvgFacebook from './components/SVG/Facebook.vue'
import SvgTwitter from './components/SVG/Twitter.vue'

export default {
  name: 'App',
  components: {
    HeaderLayout,
    Item,
    Loading,
    SvgFacebook,
    SvgTwitter,
  },
  data () {
    return {
      isLoading: false,
      itemAPI: null,
      itemData: {},
      idsExclude: [],
      price: '',
      inputWidth: 0,
      maxPrice: 400000000000,
      maxItems: 2000,
      stopSearch: false,
      warningPrice: false
    }
  },
  computed: {
    isDisabled: function(){
      return this.stopSearch;
    },
  },
  mounted() {
    if (window.innerWidth <= 1090) {
      this.inputWidth = 87
    } else {
      this.inputWidth = 130
    }
  },
  methods: {
    findItem() {
      if (! this.stopSearch && parseInt(this.price)) {
        this.warningPrice = false

        if (parseInt(this.price) <= this.maxPrice) {
          this.isLoading = true
          this.fetchItem()
        } else {
          this.warningPrice = true
          this.itemAPI = null
          this.itemData = {}
        }
      }
    },
    async fetchItem() {
      const stuff = await this.$prismic.client.query([
        this.$prismic.Predicates.at( 'document.type', 'items' ),
        this.$prismic.Predicates.inRange( 'my.items.price', 0, parseInt(this.price) + 1 ),
        this.idsExclude.join('')
      ])

      if (stuff.results !== null) {
        let countItems = stuff.results.length
        let randomKey = Math.floor(Math.random() * countItems)
        this.itemAPI = stuff.results[randomKey]
        this.itemData['count'] = Math.floor(this.price / this.itemAPI.data.price)

        if (this.itemData['count'] > this.maxItems) {
          this.itemData['countDisplay'] = this.maxItems
          this.itemData['itemsMissing'] = this.itemData['count'] - this.maxItems
        } else {
          this.itemData['countDisplay'] = this.itemData['count']
          this.itemData['itemsMissing'] = 0
        }

        // Exclude ID for other item
        this.idsExclude.push('[not(document.id, "' + this.itemAPI.id + '")]')

        if (countItems === 1) {
          this.stopSearch = true
        }
      } else {
        this.stopSearch = true
      }

      // Desactivate loading
      this.isLoading = false
    },
    resizeInput() {
      if (this.$refs.price.value.length <= 0) {
        if (window.innerWidth <= 1090) {
          this.inputWidth = 87
        } else {
          this.inputWidth = 130
        }
      } else  {
        if (window.innerWidth <= 1090) {
          this.inputWidth = 18 * this.$refs.price.value.length
        } else {
          this.inputWidth = 27 * this.$refs.price.value.length
        }
      }

      // Enabled button
      this.stopSearch = false
      this.idsExclude = []
    },
    formatNumber(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }
  }
}
</script>

<style lang="scss">
  @import 'assets/scss/screen.scss';
</style>