<template>
  <li v-html="icon"></li>
</template>

<script>
  export default {
    props: [
        'icon',
    ],
  }
</script>